import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//礼包设置列表
export const giftList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/list",
  });

//下拉选择
export const getLedgerAll = () =>
  request({
    method: "post",
    url: "/adm/ledger/propconf/all",
  });

//添加礼包
export const addGift = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/add",
  });

//删除礼包
export const delGift = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/del",
  });

//编辑礼包
export const editGift = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/edit",
  });

//礼包列表导出
export const giftListExcel = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/shop/gift/list`,
  });

//礼包列表
export const getSendList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/send/list",
  });

//礼包发送下拉框
export const getShopGiftAll = () =>
  request({
    method: "post",
    url: "/adm/shop/gift/all ",
  });

//导出礼品发放列表
export const exportSendList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/shop/gift/send/export`,
  });

//删除礼品
export const delGiftSend = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/send/del",
  });

//添加礼品
export const addShopGiftSend = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/send/add",
  });

//编辑礼品
export const editShopGiftSend = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/send/edit",
  });

//获取道具类别属性列表
export const getShopPropmodelconfList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/propmodelconf/list",
  });

//获取道具资源列表
export const getShopGiftconfList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/giftconf/list",
  });

//添加道具资源
export const addShopGiftconf = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/giftconf/add",
  });

//编辑道具资源
export const editShopGiftconf = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/giftconf/edit",
  });

//道具赠送
export const sendShopGift = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/gift/send",
  });

//背包列表
export const getBackpackList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/backpack/list",
  });

//背包删除
export const delBackpack = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/backpack/del",
  });

//获取道具列表
export const getShopPropconfList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/propconf/list",
  });

//导出道具列表
export const exportShopPropconfList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/shop/propconf/export`,
  });

//获取博主列表
export const getBloggerLevelConf = () =>
  request({
    method: "post",
    url: "/adm/user/blogger/level/conf",
  });

//获取关联道具列表
export const getShopPropconfRelationModel = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/propconf/relation/model",
  });

//道具上下架操作
export const setShopPropconfStatus = (data: any) =>
  request({
    url: "/adm/shop/propconf/status",
    data,
    method: "post",
  });

//编辑道具
export const editShopPropconf = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/propconf/edit",
  });

//添加道具
export const addShopPropconf = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/shop/propconf/add",
  });

//获取领取记录
export const getGiftcodeRecordList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/giftcode/get/record",
  });

//导出领取记录Excel
export const exportGiftcodeRecordList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/ledger/giftcode/get/record`,
  });

//获取道具存量统计
export const giftStockStatistics = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/prop/total",
  });

//获取道具存量统计Excel
export const giftStockStatisticsExcel = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/prop/total`,
  });
