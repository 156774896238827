
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { getLedgerAll, sendShopGift } from "@/api/request/item";

//组件
@Component({
  name: "ItemSend",
})

//界面函数
export default class extends Vue {
  //定义变量
  private tags: any[] = [];
  private propList: any[] = [];
  private tagInput: string = "";
  private tagInputMore: string = "";

  //发送参数
  private sendParams: any = {
    memo: "", //备注
    type: "1", //类型
    prize: [
      {
        gift_id: "",
        gift_name: "",
        gift_num: undefined,
      },
    ],
    user: [
      {
        id: "",
        name: "",
      },
    ],
  };

  //创建
  created() {
    //获取列表
    this.getLedgerAll();
  }

  //添加道具
  private addProp(): void {
    //显示对话框
    this.sendDialog = true;
  }

  //礼包发放
  private async sendGift() {
    //判断返回
    if (!this.giftList.length) return this.$message.error("礼物列表不能为空");
    if (this.sendParams.memo.trim() == "") return this.$message.error("请输入赠送备注");
    if (this.sendParams.type === "1" && !this.tags.length) return this.$message.error("请输入用户ID");

    //提示信息赋值
    let idStr = "";
    let giftStr = "";
    this.giftList.forEach((item: any) => {
      giftStr += ` ${item.gift_name} X ${item.gift_num},`;
    });
    this.tags.forEach((item: any) => {
      idStr += ` ${item},`;
    });

    //显示提示
    this.$confirm(`确定赠送${giftStr}至用户${idStr}?`, "Warning", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //用户赋值
        const user: any = [];
        if (this.tags.length) {
          this.tags.forEach((item: any) => {
            user.push({ id: Number(item) });
          });
        }
        this.sendParams.user = user;

        //发送数据
        const data: any = { ...this.sendParams, prize: this.giftList, type: Number(this.sendParams.type) };

        //道具怎送
        const res: any = await sendShopGift(data);

        //怎送成功
        if (res.status === 0) {
          //显示提示
          this.$message.success("赠送成功");

          //清空数据
          this.tags = [];
          this.giftList = [];
          this.sendParams.memo = "";
          this.sendParams.type = "1";
          this.sendParams.user = [{ id: "", name: "" }];
          this.sendParams.prize[0].gift_num = undefined;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //获取数据
  private async getLedgerAll() {
    //获取数据
    const res = await getLedgerAll();

    //数据赋值
    this.propList = res.data;
  }

  //添加ID
  private addTag(type: any): void {
    //定义变量
    let errorList: any = [];

    //批量添加
    if (type === "more") {
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item: any) => {
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());

          //数据赋值
          this.tags.push(item.trim());

          //清空输入框
          this.tagInputMore = "";
        });

      //返回错误
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        //数据赋值
        this.tags.push(this.tagInput.trim());

        //清空输入框
        this.tagInput = "";
      } else {
        //显示提示
        this.$message.error("请输入正确形式的ID");
        //清空输入框
        this.tagInput = "";
      }
    }
  }

  //删除道具
  private delGift(index: any): void {
    //数据赋值
    this.giftList.splice(index, 1);
  }

  //移除ID
  private removeTag(index: any): void {
    //数据赋值
    this.tags.splice(index, 1);
  }

  //--------------------------- 道具列表 --------------------------------
  //定义变量
  private giftList: any = [];
  private sendDialog: boolean = false;

  //添加礼物
  private addGift(row: any): void {
    //数据赋值
    this.giftList.push({ gift_id: row.propid, gift_name: row.propname, gift_num: 1 });

    //隐藏对话框
    this.sendDialog = false;
  }
}
